<template>
  <div>
    <div class="course-details-wrapper topic-1 uk-light pt-5">


      <div class="container p-sm-0">

        <div uk-grid="" class="uk-grid uk-grid-stack">
          <div class="uk-width-2-3@m uk-first-column" style="margin-left:27rem;">


            <div class="d-flex">
              <nav id="breadcrumbs" class="mb-3">
                <ul>
                  <li><a href="#"> <i class="uil-home-alt"></i> </a></li>
                  <li>Katalog Eğitimleri</li>
                  <li><a href="">Zorunlu Eğitimler</a></li>
                  <li>İş Sağlığı ve Güvenliği</li>
                </ul>
              </nav>
            </div>


            <div class="course-details">
              <span>Zorunlu Eğitimler</span>
              <h1>İş Sağlığı ve Güvenliği</h1>

              <div class="course-details-info mt-4">
                <ul>
                  <li><i class="uil-bell"></i> <b> 12</b> kullanıcı bu eğitime istek gönderdi</li>
                </ul>
              </div>
              <!-- <div class="course-details-info">
                  <ul>
                      <li> <i class="icon-feather-users"></i> </li>
                  </ul>
              </div> -->
            </div>
            <nav class="responsive-tab style-5">
              <ul uk-switcher="connect: #course-intro-tab ;animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium">
                <li class="uk-active"><a href="#" aria-expanded="true"><i class="uil-list-ul"></i>{{this.$t('general.contents')}}</a></li>
                <li class=""><a href="#" aria-expanded="false"><i class="uil-calendar-alt"></i> Satınalma Taleplerim</a></li>
                <li class=""><a href="#" aria-expanded="false"><i class="uil-comment-info-alt"></i> {{this.$t('general.experience_sharing')}}</a></li>
              </ul>
            </nav>
          </div>
        </div>

      </div>
    </div>

    <div class="container">

      <div class="uk-grid-large mt-4 uk-grid" uk-grid="">
        <div class="uk-width-2-3@m">
          <ul id="course-intro-tab" class="uk-switcher mt-4" style="touch-action: pan-y pinch-zoom;">
            <!-- course Curriculum-->
            <li class="uk-active  " style="">
              <h4>Eğitim Hakkında</h4>
              <p class="mb-4">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                nonummy nibh
                euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
                minim laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea
                commodo consequat</p>
              <p class="mb-4">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                nonummy nibh
                euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
                minim laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea
                commodo consequat</p>

              <div uk-margin="" class="mb-4">
                <a href="arama.html" class="btn btn-small btn-light uk-first-column">#isg</a>
                <a href="arama.html" class="btn btn-small btn-light">#işsağlığı</a>
                <a href="arama.html" class="btn btn-small btn-light">#işkazaları</a>
                <a href="arama.html" class="btn btn-small btn-light">#zorunlueğitim</a>
              </div>

              <ul class="course-curriculum mb-5 uk-accordion" uk-accordion="multiple: true">
                <li class="uk-open">
                  <a class="uk-accordion-title" href="#"> Bölüm 1</a>
                  <div class="uk-accordion-content" aria-hidden="false">

                    <!-- course-video-list -->
                    <ul class="course-curriculum-list">
                      <li class="read completed"><a href="">Lorem ipsum dolor sit amet consectetur adipisicing elit
                        dolor</a><span><i class="uil-check-circle"></i> Demo
                                                    </span>
                      </li><li class="watch completed"><a href="">Consectetur adipisicing elit</a> <span><i class="uil-check-circle"></i> Demo</span> </li>
                      <li class="watch completed"><a href="">Lorem ipsum dolor sit amet </a><span><i class="uil-check-circle"></i> Demo</span></li>
                      <li class="quiz"><a href="" style="cursor:no-drop">Lorem
                        Ipsum </a><span class="uil-lock-alt"></span> </li>
                      <li class="interactive"><a style="cursor:no-drop" href="">Adipisicing elit adipisicing </a><span class="uil-lock-alt"></span>
                      </li>
                    </ul>

                  </div>
                </li>
                <li class="uk-open">
                  <a class="uk-accordion-title" href="#"> Bölüm 2 </a>
                  <div class="uk-accordion-content" aria-hidden="false">
                    <!-- course-video-list -->
                    <ul class="course-curriculum-list">
                      <li class="read completed"><a href="">Lorem ipsum dolor sit amet consectetur adipisicing elit
                        dolor</a><span><i class="uil-check-circle"></i> 10 puan
                                                    </span>
                      </li><li class="watch completed"><a href="">Consectetur adipisicing elit</a> <span><i class="uil-check-circle"></i> 5 puan</span> </li>
                      <li class="watch completed"><a href="">Lorem ipsum dolor sit amet </a><span><i class="uil-check-circle"></i> 5 puan</span></li>
                      <li class="quiz"><a href="">Lorem
                        Ipsum </a><span> 15 puan</span> </li>
                      <li class="interactive"><a href="">Adipisicing elit adipisicing </a><span> 20 puan</span>
                      </li>
                      <li class="read completed"><a href="">Lorem ipsum dolor sit amet consectetur adipisicing elit
                        dolor</a><span><i class="uil-check-circle"></i> 10 puan
                                                    </span>
                      </li><li class="watch completed"><a href="">Consectetur adipisicing elit</a> <span><i class="uil-check-circle"></i> 5 puan</span> </li>
                      <li class="watch completed"><a href="">Lorem ipsum dolor sit amet </a><span><i class="uil-check-circle"></i> 5 puan</span></li>
                      <li class="quiz"><a href="">Lorem
                        Ipsum </a><span> 15 puan</span> </li>
                      <li class="interactive"><a href="">Adipisicing elit adipisicing </a><span> 20 puan</span>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="">
                  <a class="uk-accordion-title" href="#"> Bölüm 3 </a>
                  <div class="uk-accordion-content" hidden="" aria-hidden="true">
                    <!-- course-video-list -->
                    <ul class="course-curriculum-list">
                      <li class="read completed"><a href="">Lorem ipsum dolor sit amet consectetur adipisicing elit
                        dolor</a><span><i class="uil-check-circle"></i> 10 puan
                                                    </span>
                      </li><li class="watch completed"><a href="">Consectetur adipisicing elit</a> <span><i class="uil-check-circle"></i> 5 puan</span> </li>
                      <li class="watch completed"><a href="">Lorem ipsum dolor sit amet </a><span><i class="uil-check-circle"></i> 5 puan</span></li>
                      <li class="quiz"><a href="">Lorem
                        Ipsum </a><span> 15 puan</span> </li>
                      <li class="interactive"><a href="">Adipisicing elit adipisicing </a><span> 20 puan</span>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="">
                  <a class="uk-accordion-title" href="#"> Bölüm 4 </a>
                  <div class="uk-accordion-content" hidden="" aria-hidden="true">
                    <!-- course-video-list -->
                    <ul class="course-curriculum-list">
                      <li class="read completed"><a href="">Lorem ipsum dolor sit amet consectetur adipisicing elit
                        dolor</a><span><i class="uil-check-circle"></i> 10 puan
                                                    </span>
                      </li><li class="watch completed"><a href="">Consectetur adipisicing elit</a> <span><i class="uil-check-circle"></i> 5 puan</span> </li>
                      <li class="watch completed"><a href="">Lorem ipsum dolor sit amet </a><span><i class="uil-check-circle"></i> 5 puan</span></li>
                      <li class="quiz"><a href="">Lorem
                        Ipsum </a><span> 15 puan</span> </li>
                      <li class="interactive"><a href="">Adipisicing elit adipisicing </a><span> 20 puan</span>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </li>

            <li class="" style="">
              <h4>Satınalma Taleplerim</h4>

              <div class="table-responsive">
                <table class="table align-items-center">
                  <thead>
                  <tr>
                    <th scope="col">Talep Tipi</th>
                    <th scope="col">Talep Tarihi</th>
                    <th scope="col">Durum</th>
                    <th scope="col">Onay Tarihi</th>
                    <th scope="col"> </th>
                  </tr>
                  </thead>
                  <tbody class="list">
                  <tr>
                    <th scope="row">
                      <div class="media align-items-center">

                        <div class="media-body">
                          <p href="#" class="name h6 mb-0 text-sm">İstek Gönderenler (12 kullanıcı)</p>
                          <small class="d-block font-weight-bold">#1520</small>
                        </div>
                      </div>
                    </th>
                    <td>09/12/2020 10:11:02</td>
                    <td><i class="uil-clock-eight"></i> Yanıt bekleniyor</td>
                    <td> - </td>
                    <td class="text-right">
                      <!-- Actions -->
                      <div class="actions ml-3">
                        <a href="#" class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Yöneticiye Mesaj Gönder" title="" aria-expanded="false">
                          <i class="uil-envelope "></i> </a>

                        <a href="#" class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Talebi İptal Et" title="" aria-expanded="false">
                          <i class="uil-times-circle text-danger"></i> </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div class="media align-items-center">

                        <div class="media-body">
                          <p href="#" class="name h6 mb-0 text-sm">Özel Kullanıcı Sayısı (50)</p>
                          <small class="d-block font-weight-bold">#1524</small>
                        </div>
                      </div>
                    </th>
                    <td>09/12/2020 10:11:02</td>
                    <td><i class="uil-clock-eight"></i> Yanıt bekleniyor</td>
                    <td> - </td>
                    <td class="text-right">
                      <!-- Actions -->
                      <div class="actions ml-3">
                        <a href="#" class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Yöneticiye Mesaj Gönder" title="" aria-expanded="false">
                          <i class="uil-envelope "></i> </a>
                        <a href="#" class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Talebi İptal Et" title="" aria-expanded="false">
                          <i class="uil-times-circle text-danger"></i> </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div class="media align-items-center">
                        <div class="media-body">
                          <p href="#" class="name h6 mb-0 text-sm">Özel Kullanıcı Sayısı (100)</p>
                          <small class="d-block font-weight-bold">#1524</small>
                        </div>
                      </div>
                    </th>
                    <td>01/11/2020 15:18:02</td>
                    <td style="color:green;font-weight: bold;"> Onay verildi</td>
                    <td> 03/11/2020 16:48:02 </td>
                    <td class="text-right">
                      <!-- Actions -->
                      <div class="actions ml-3">
                        <a href="#" class="btn btn-primary btn-hover btn-sm"><i class="uil-location-arrow"></i>
                          {{$t('menu.company_dashboard.create_assignment')}}</a>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>


            </li>

            <!-- course description -->
            <li class="course-description-content" style="">

              <div class="comments mt-4">
                <h4>{{this.$t('general.experience_sharing')}}
                  <span class="comments-amount">(24 Paylaşım)</span>
                </h4>
                <ul>
                  <li>
                    <div class="comments-avatar"><img src="@/assets/images//avatars/avatar-1.jpg" alt="">
                    </div>
                    <div class="comment-content">
                      <div class="comment-by">Selma Güneri <span></span>
                      </div>
                      <p> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                        nonummy nibh
                        euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                        wisi enim ad
                        minim veniam, quis nostrud exerci tation ullamcorper suscipit
                        lobortis nisl ut
                        aliquip ex ea commodo consequat. Nam liber tempor cum soluta nobis
                        eleifend
                        option
                        congue </p>
                    </div>



                  </li>

                  <li>
                    <div class="comments-avatar"><img src="@/assets/images/avatars/avatar-4.jpg" alt="">
                    </div>
                    <div class="comment-content">

                      <div class="comment-by">Salih Yıldırım<span>Takım Lideri</span>
                      </div>
                      <p>Nam liber tempor cum soluta nobis eleifend option congue ut laoreet
                        dolore magna
                        aliquam erat volutpat nihil imperdiet doming id quod mazim placerat
                        facer possim
                        assum. Lorem ipsum dolor sit amet</p>
                    </div>

                  </li>

                  <li>
                    <div class="comments-avatar"><img src="@/assets/images//avatars/avatar-1.jpg" alt="">
                    </div>
                    <div class="comment-content">
                      <div class="comment-by">Selma Güneri <span></span>
                      </div>
                      <p> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                        nonummy nibh
                        euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                        wisi enim ad
                        minim veniam, quis nostrud exerci tation ullamcorper suscipit
                        lobortis nisl ut
                        aliquip ex ea commodo consequat. Nam liber tempor cum soluta nobis
                        eleifend
                        option
                        congue </p>
                    </div>



                  </li>

                  <li>
                    <div class="comments-avatar"><img src="@/assets/images//avatars/avatar-1.jpg" alt="">
                    </div>
                    <div class="comment-content">
                      <div class="comment-by">Selma Güneri <span></span>
                      </div>
                      <p> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                        nonummy nibh
                        euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                        wisi enim ad
                        minim veniam, quis nostrud exerci tation ullamcorper suscipit
                        lobortis nisl ut
                        aliquip ex ea commodo consequat. Nam liber tempor cum soluta nobis
                        eleifend
                        option
                        congue </p>
                    </div>



                  </li>

                  <li>
                    <div class="comments-avatar"><img src="@/assets/images//avatars/avatar-1.jpg" alt="">
                    </div>
                    <div class="comment-content">
                      <div class="comment-by">Selma Güneri <span></span>
                      </div>
                      <p> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                        nonummy nibh
                        euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                        wisi enim ad
                        minim veniam, quis nostrud exerci tation ullamcorper suscipit
                        lobortis nisl ut
                        aliquip ex ea commodo consequat. Nam liber tempor cum soluta nobis
                        eleifend
                        option
                        congue </p>
                    </div>



                  </li>

                  <li>
                    <div class="comments-avatar"><img src="@/assets/images//avatars/avatar-1.jpg" alt="">
                    </div>
                    <div class="comment-content">
                      <div class="comment-by">Selma Güneri <span></span>
                      </div>
                      <p> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                        nonummy nibh
                        euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                        wisi enim ad
                        minim veniam, quis nostrud exerci tation ullamcorper suscipit
                        lobortis nisl ut
                        aliquip ex ea commodo consequat. Nam liber tempor cum soluta nobis
                        eleifend
                        option
                        congue </p>
                    </div>



                  </li>

                  <li>
                    <div class="comments-avatar"><img src="@/assets/images//avatars/avatar-1.jpg" alt="">
                    </div>
                    <div class="comment-content">
                      <div class="comment-by">Selma Güneri <span></span>
                      </div>
                      <p> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                        nonummy nibh
                        euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                        wisi enim ad
                        minim veniam, quis nostrud exerci tation ullamcorper suscipit
                        lobortis nisl ut
                        aliquip ex ea commodo consequat. Nam liber tempor cum soluta nobis
                        eleifend
                        option
                        congue </p>
                    </div>



                  </li>

                  <li>
                    <div class="comments-avatar"><img src="@/assets/images//avatars/avatar-1.jpg" alt="">
                    </div>
                    <div class="comment-content">
                      <div class="comment-by">Selma Güneri <span></span>
                      </div>
                      <p> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                        nonummy nibh
                        euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                        wisi enim ad
                        minim veniam, quis nostrud exerci tation ullamcorper suscipit
                        lobortis nisl ut
                        aliquip ex ea commodo consequat. Nam liber tempor cum soluta nobis
                        eleifend
                        option
                        congue </p>
                    </div>



                  </li>


                </ul>

              </div>

              <div class="comments">
                <h4>Deneyim Yaz</h4>
                <ul>
                  <li>
                    <div class="comments-avatar"><img src="@/assets/images/avatars/avatar-2.jpg" alt="">
                    </div>
                    <div class="comment-content">
                      <form class="uk-grid-small uk-grid uk-grid-stack" uk-grid="">

                        <div class="uk-width-1-1@s uk-first-column">
                          <label class="uk-form-label">Yazı</label>
                          <textarea class="uk-textarea" placeholder="Buraya deneyimlerini yaz..." style=" height:160px"></textarea>
                        </div>
                        <div class="uk-grid-margin uk-first-column">
                          <input type="submit" value="Gönder" class="btn btn-default">
                        </div>
                      </form>

                    </div>
                  </li>
                </ul>
              </div>
            </li>

          </ul>
        </div>

        <div class="uk-width-1-3@m uk-flex-first uk-first-column">
          <div class="course-card-trailer uk-sticky" uk-sticky="top: 10 ;offset:95 ; media: @m ; bottom:true" style="">

            <div class="course-thumbnail">
              <img src="@/assets/images/course/1.png" alt="">
            </div>


            <div class="p-3">


              <div class="uk-child-width-2-2 uk-grid-small mb-4 uk-grid uk-grid-stack" uk-grid="">

                <div class="uk-first-column">
                  <div class="bg-gradient-danger shadow-danger uk-light mt-3 uk-alert" uk-alert=""> <a class="uk-alert-close uk-icon uk-close" uk-close=""><svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" data-svg="close-icon"><line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line><line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line></svg></a> <p>200 kullanıcı ve üzeri eğitimlerde %15'lik indirim fırsatını kaçırmayın!</p> </div>

                  <select class="mt-3" name="" id="">

                    <option value="option1" selected="">İstek gönderen kadar kullanıcı</option>
                    <option value="option2">Özel kullanıcı sayısı</option>
                  </select>
                </div>
                <div class="uk-grid-margin uk-first-column">
                  <a id="btn-requestlist" href="#modal-ruquestlist" uk-toggle="" class="uk-width-1-1 btn btn-secondary transition-3d-hover">
                    <i class="uil-bell"></i> İstek Gönderenler Listesi (12)
                  </a>


                  <div id="modal-ruquestlist" uk-modal="" class="uk-modal">
                    <div class="uk-modal-dialog"> <button class="uk-modal-close-default uk-icon uk-close" type="button" uk-close=""><svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" data-svg="close-icon"><line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line><line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line></svg></button>
                      <div class="uk-modal-header">
                        <h2 class="uk-modal-title">İstek Yapan Kullanıcılar</h2>
                      </div>

                      <div class="uk-modal-body uk-overflow-auto" uk-overflow-auto="" style="min-height: 150px; max-height: 150px;">

                        <div class="uk-grid-small uk-flex-middle position-relative uk-grid uk-grid-stack" uk-grid="">
                          <div class="uk-width-auto position-relative">
                            <img class="rounded-circle avatar-sm" src="@/assets/images/avatars/avatar-2.jpg">
                          </div>
                          <div class="uk-width-expand">
                            <p class="uk-text-truncate mb-0"><b> Ahmet Güler</b></p>
                            <p>İstek Tarihi: 21 Aralık 2020 14:55</p>
                          </div>
                          <div>

                                                            <span class="btn-option userlist" aria-expanded="false">
                                                                <i class="icon-feather-more-vertical"></i>
                                                            </span>
                            <div class="dropdown-option-nav uk-dropdown" uk-dropdown="pos: bottom-right ;mode : hover">
                              <ul>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user"></i>
                                                                            Profili İncele</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-message"></i>
                                                                            Mesaj Gönder</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user-times"></i>
                                                                            İstek Listesinden Kaldır</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="uk-grid-small uk-flex-middle position-relative uk-grid uk-grid-stack" uk-grid="">
                          <div class="uk-width-auto position-relative">
                            <img class="rounded-circle avatar-sm" src="@/assets/images/avatars/avatar-1.jpg">
                          </div>
                          <div class="uk-width-expand">
                            <p class="uk-text-truncate mb-0"><b> Selma Güner</b></p>
                            <p>İstek Tarihi: 18 Aralık 2020 14:55</p>
                          </div>
                          <div>

                                                            <span class="btn-option userlist" aria-expanded="false">
                                                                <i class="icon-feather-more-vertical"></i>
                                                            </span>
                            <div class="dropdown-option-nav uk-dropdown" uk-dropdown="pos: bottom-right ;mode : hover">
                              <ul>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user"></i>
                                                                            Profili İncele</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-message"></i>
                                                                            Mesaj Gönder</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user-times"></i>
                                                                            İstek Listesinden Kaldır</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>


                        <div class="uk-grid-small uk-flex-middle position-relative uk-grid uk-grid-stack" uk-grid="">
                          <div class="uk-width-auto position-relative">
                            <img class="rounded-circle avatar-sm" src="@/assets/images/avatars/avatar-2.jpg">
                          </div>
                          <div class="uk-width-expand">
                            <p class="uk-text-truncate mb-0"><b> Ahmet Güler</b></p>
                            <p>İstek Tarihi: 21 Aralık 2020 14:55</p>
                          </div>
                          <div>

                                                            <span class="btn-option userlist" aria-expanded="false">
                                                                <i class="icon-feather-more-vertical"></i>
                                                            </span>
                            <div class="dropdown-option-nav uk-dropdown" uk-dropdown="pos: bottom-right ;mode : hover">
                              <ul>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user"></i>
                                                                            Profili İncele</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-message"></i>
                                                                            Mesaj Gönder</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user-times"></i>
                                                                            İstek Listesinden Kaldır</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="uk-grid-small uk-flex-middle position-relative uk-grid uk-grid-stack" uk-grid="">
                          <div class="uk-width-auto position-relative">
                            <img class="rounded-circle avatar-sm" src="@/assets/images/avatars/avatar-1.jpg">
                          </div>
                          <div class="uk-width-expand">
                            <p class="uk-text-truncate mb-0"><b> Selma Güner</b></p>
                            <p>İstek Tarihi: 18 Aralık 2020 14:55</p>
                          </div>
                          <div>

                                                            <span class="btn-option userlist" aria-expanded="false">
                                                                <i class="icon-feather-more-vertical"></i>
                                                            </span>
                            <div class="dropdown-option-nav uk-dropdown" uk-dropdown="pos: bottom-right ;mode : hover">
                              <ul>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user"></i>
                                                                            Profili İncele</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-message"></i>
                                                                            Mesaj Gönder</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user-times"></i>
                                                                            İstek Listesinden Kaldır</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>


                        <div class="uk-grid-small uk-flex-middle position-relative uk-grid uk-grid-stack" uk-grid="">
                          <div class="uk-width-auto position-relative">
                            <img class="rounded-circle avatar-sm" src="@/assets/images/avatars/avatar-2.jpg">
                          </div>
                          <div class="uk-width-expand">
                            <p class="uk-text-truncate mb-0"><b> Ahmet Güler</b></p>
                            <p>İstek Tarihi: 21 Aralık 2020 14:55</p>
                          </div>
                          <div>

                                                            <span class="btn-option userlist" aria-expanded="false">
                                                                <i class="icon-feather-more-vertical"></i>
                                                            </span>
                            <div class="dropdown-option-nav uk-dropdown" uk-dropdown="pos: bottom-right ;mode : hover">
                              <ul>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user"></i>
                                                                            Profili İncele</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-message"></i>
                                                                            Mesaj Gönder</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user-times"></i>
                                                                            İstek Listesinden Kaldır</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>


                        <div class="uk-grid-small uk-flex-middle position-relative uk-grid uk-grid-stack" uk-grid="">
                          <div class="uk-width-auto position-relative">
                            <img class="rounded-circle avatar-sm" src="@/assets/images/avatars/avatar-2.jpg">
                          </div>
                          <div class="uk-width-expand">
                            <p class="uk-text-truncate mb-0"><b> Selma Güner</b></p>
                            <p>İstek Tarihi: 18 Aralık 2020 14:55</p>
                          </div>
                          <div>

                                                            <span class="btn-option userlist" aria-expanded="false">
                                                                <i class="icon-feather-more-vertical"></i>
                                                            </span>
                            <div class="dropdown-option-nav uk-dropdown" uk-dropdown="pos: bottom-right ;mode : hover">
                              <ul>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user"></i>
                                                                            Profili İncele</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-message"></i>
                                                                            Mesaj Gönder</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user-times"></i>
                                                                            İstek Listesinden Kaldır</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>


                        <div class="uk-grid-small uk-flex-middle position-relative uk-grid uk-grid-stack" uk-grid="">
                          <div class="uk-width-auto position-relative">
                            <img class="rounded-circle avatar-sm" src="@/assets/images/avatars/avatar-2.jpg">
                          </div>
                          <div class="uk-width-expand">
                            <p class="uk-text-truncate mb-0"><b> Ahmet Güler</b></p>
                            <p>İstek Tarihi: 21 Aralık 2020 14:55</p>
                          </div>
                          <div>

                                                            <span class="btn-option userlist" aria-expanded="false">
                                                                <i class="icon-feather-more-vertical"></i>
                                                            </span>
                            <div class="dropdown-option-nav uk-dropdown" uk-dropdown="pos: bottom-right ;mode : hover">
                              <ul>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user"></i>
                                                                            Profili İncele</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-message"></i>
                                                                            Mesaj Gönder</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user-times"></i>
                                                                            İstek Listesinden Kaldır</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="uk-grid-small uk-flex-middle position-relative uk-grid uk-grid-stack" uk-grid="">
                          <div class="uk-width-auto position-relative">
                            <img class="rounded-circle avatar-sm" src="@/assets/images/avatars/avatar-2.jpg">
                          </div>
                          <div class="uk-width-expand">
                            <p class="uk-text-truncate mb-0"><b> Selma Güner</b></p>
                            <p>İstek Tarihi: 18 Aralık 2020 14:55</p>
                          </div>
                          <div>

                                                            <span class="btn-option userlist" aria-expanded="false">
                                                                <i class="icon-feather-more-vertical"></i>
                                                            </span>
                            <div class="dropdown-option-nav uk-dropdown" uk-dropdown="pos: bottom-right ;mode : hover">
                              <ul>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user"></i>
                                                                            Profili İncele</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-message"></i>
                                                                            Mesaj Gönder</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user-times"></i>
                                                                            İstek Listesinden Kaldır</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>


                        <div class="uk-grid-small uk-flex-middle position-relative uk-grid uk-grid-stack" uk-grid="">
                          <div class="uk-width-auto position-relative">
                            <img class="rounded-circle avatar-sm" src="@/assets/images/avatars/avatar-2.jpg">
                          </div>
                          <div class="uk-width-expand">
                            <p class="uk-text-truncate mb-0"><b> Ahmet Güler</b></p>
                            <p>İstek Tarihi: 21 Aralık 2020 14:55</p>
                          </div>
                          <div>

                                                            <span class="btn-option userlist" aria-expanded="false">
                                                                <i class="icon-feather-more-vertical"></i>
                                                            </span>
                            <div class="dropdown-option-nav uk-dropdown" uk-dropdown="pos: bottom-right ;mode : hover">
                              <ul>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user"></i>
                                                                            Profili İncele</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-message"></i>
                                                                            Mesaj Gönder</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user-times"></i>
                                                                            İstek Listesinden Kaldır</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="uk-grid-small uk-flex-middle position-relative uk-grid uk-grid-stack" uk-grid="">
                          <div class="uk-width-auto position-relative">
                            <img class="rounded-circle avatar-sm" src="@/assets/images/avatars/avatar-2.jpg">
                          </div>
                          <div class="uk-width-expand">
                            <p class="uk-text-truncate mb-0"><b> Selma Güner</b></p>
                            <p>İstek Tarihi: 18 Aralık 2020 14:55</p>
                          </div>
                          <div>

                                                            <span class="btn-option userlist" aria-expanded="false">
                                                                <i class="icon-feather-more-vertical"></i>
                                                            </span>
                            <div class="dropdown-option-nav uk-dropdown" uk-dropdown="pos: bottom-right ;mode : hover">
                              <ul>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user"></i>
                                                                            Profili İncele</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-message"></i>
                                                                            Mesaj Gönder</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user-times"></i>
                                                                            İstek Listesinden Kaldır</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="uk-grid-small uk-flex-middle position-relative uk-grid uk-grid-stack" uk-grid="">
                          <div class="uk-width-auto position-relative">
                            <img class="rounded-circle avatar-sm" src="@/assets/images/avatars/avatar-2.jpg">
                          </div>
                          <div class="uk-width-expand">
                            <p class="uk-text-truncate mb-0"><b> Ahmet Güler</b></p>
                            <p>İstek Tarihi: 21 Aralık 2020 14:55</p>
                          </div>
                          <div>

                                                            <span class="btn-option userlist" aria-expanded="false">
                                                                <i class="icon-feather-more-vertical"></i>
                                                            </span>
                            <div class="dropdown-option-nav uk-dropdown" uk-dropdown="pos: bottom-right ;mode : hover">
                              <ul>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user"></i>
                                                                            Profili İncele</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-message"></i>
                                                                            Mesaj Gönder</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user-times"></i>
                                                                            İstek Listesinden Kaldır</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="uk-grid-small uk-flex-middle position-relative uk-grid uk-grid-stack" uk-grid="">
                          <div class="uk-width-auto position-relative">
                            <img class="rounded-circle avatar-sm" src="@/assets/images/avatars/avatar-2.jpg">
                          </div>
                          <div class="uk-width-expand">
                            <p class="uk-text-truncate mb-0"> Ahmet Güler</p>
                          </div>
                          <div>

                                                            <span class="btn-option userlist" aria-expanded="false">
                                                                <i class="icon-feather-more-vertical"></i>
                                                            </span>
                            <div class="dropdown-option-nav uk-dropdown" uk-dropdown="pos: bottom-right ;mode : hover">
                              <ul>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user"></i>
                                                                            Profili İncele</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-message"></i>
                                                                            Mesaj Gönder</span>
                                </li>
                                <li>
                                                                        <span>
                                                                            <i class="uil-user-times"></i>
                                                                            İstek Listesinden Kaldır</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>






                      </div>





                    </div>
                  </div>

                  <div class="d-flex justify-content-between mt-4">
                    <div class="d-flex align-items-center">
                      <span style="font-size: .875rem;height:38px;margin-right:13px;"><i class="uil-user"></i> Kişi Sayısı:</span>
                      <input id="user-count" style="width:80px;" type="number" disabled="" value="12">

                    </div>
                    <span class="uk-h4 mt-2"> 7.499 TL </span>
                  </div>

                  <div>
                    <a href="egitim-player.html" class="uk-width-1-1 btn btn-success transition-3d-hover"> <i class="uil-shopping-basket"></i> Satınalma Talebi Gönder</a>
                  </div>
                  <!-- <div>
                      <a href="egitim-player.html"
                          class="uk-width-1-1 btn btn-primary transition-3d-hover"> <i
                              class="uil-brightness-plus"></i> Koleksiyona Ekle </a>
                  </div>
                  <div>
                      <a href="egitim-player.html"
                          class="uk-width-1-1 btn btn-primary transition-3d-hover"> <i
                              class="uil-check-circle"></i> Öner </a>
                  </div> -->
                </div>


                <!--<p class="uk-text-bold"> Eğitim İçeriği</p>

                <div class="uk-child-width-1-2 uk-grid-small" uk-grid>
                    <div>
                        <span> <i class="uil-file-alt"></i> 4 Bölüm</span>
                    </div>
                    <div>
                        <span><i class="uil-youtube-alt"></i> 17 Video</span>
                    </div>
                    <div>
                        <span> <i class="uil-document-layout-left"></i> 20 Okuma</span>
                    </div>
                    <div>
                        <span> <i class="uil-presentation-edit"></i> 3 İnteraktif</span>
                    </div>
                    <div>
                        <span> <i class="uil-award"></i> Sertifikalı </span>
                    </div>
                    <div>
                        <span> <i class="uil-gift"></i> {{this.$t('general.give_a_gift_at_the_end')}} </span>
                    </div>
                </div>-->
              </div>
            </div>

          </div><div class="uk-sticky-placeholder" style="height: 594px; margin: -300px 0px 0px;" hidden=""></div>

        </div>




      </div>

    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DataTable from "@/assets/components/dataTable/DataTable";
export default {
  name: "QuestionList",
  components: {DataTable},
  data() {
    return {

    }
  },

  computed: {

  },
  methods: {

  },
  mounted() {

  }
};
</script>
<style scoped>
input[type="button"], input[type="submit"], span.btn, .btn{
  display: inline-block;
}
</style>
